<template>
  <div>
    <b-container class="mt-5">
      <b-row class="mt-5">
        <b-col cols="6" offset="3" class="text-center">
          <br /><br />

          <div class="col h-100 my-auto pt-5 ">
            <h4
              v-if="!error"
              class="font-weight-light text-center pt-5 my-auto"
            >
              Loading...
            </h4>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "SignUp",
  data() {
    return {
      error: false,
    };
  },
  methods: {
    ...mapActions("Auth", ["reauth"]),
    ...mapActions(["DontShowModal"]),
    ...mapMutations(["setCameFromSignUp"])
  },
  beforeMount() {
    this.$bvModal.show('signup-modal')
    this.setCameFromSignUp(true)
    this.$store.commit('DontShowModal')
    this.$router.replace('/')
  },
};
</script>

<style scoped></style>
